import React, { Component } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Edit, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import '../../css/syncfusion.css';

class App extends Component {
  state = {
    data: []
  };

  componentDidMount() {
      axios.get('http://frandiaz.info:7260/api/orders')
      .then(response => {
        this.setState({ data: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  }

  // npm TODO: Hacer que las toolbarOptions guarden en la base de datos

  render() {
    const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    const toolbarOptions = []; //['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    return (
      <GridComponent dataSource={this.state.data} editSettings={editOptions} toolbar={toolbarOptions} height={265}>
        <ColumnsDirective>
          <ColumnDirective field='Order ID' headerText='Order ID' width='100' textAlign="Right" isPrimaryKey={true}/>
          <ColumnDirective field='Customer ID' headerText='Customer ID' width='120'/>
          <ColumnDirective field='Freight' headerText='Freight' width='120' format="C2" editType='numericedit' textAlign="Right"/>
          <ColumnDirective field='Ship Country' headerText='Ship Country' editType='dropdownedit' width='150'/>
        </ColumnsDirective>
        <Inject services={[Edit, Toolbar]}/>
      </GridComponent>
    );
  }
}

export default App;
