import React from 'react';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import TodoGrid from './TodoGrid';

const TodoPage = () => {

    return (
        <React.Fragment>

            <Header />

            {/* Titulo */}
            <section className="wrapper" style={{ paddingBottom: 0 }}>
                <div className="inner">
                    <header className="align-center">
                        <h1 id="titulo">Pendientes de aprender</h1>
                    </header>

                    <p>Esta pagina no es confidencial pero esta oculta a simple vista</p>

                    <TodoGrid />
                </div>
            </section>

            <Footer />

        </React.Fragment>
    )
}

export default TodoPage