//import logo from './logo.svg';
//import './App.css';
import React from 'react';
import './css/main.css';
import './css/font-awesome.min.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import PortfolioSeatPage from './components/PortfolioSeatPage/PortfolioSeatPage';
import BlogPage from './components/BlogPage/BlogPage';
import Begimo3Page from './components/CodPages/Begimo3';
import StackPage from './components/StackPage/StackPage';
import TodoPage from './components/TodoPage/TodoPage';
import CustomersPage from './components/ReactExampleSection/CustomersPage';
import OrdersPage from './components/ReactExampleSection/OrdersPage';
import LoadPage from './components/ReactExampleSection/LoadPage';

function App() {
    return (
        <React.Fragment>
            <Router>
                <Routes>
                    <Route path="/" Component={MainPage} />
                    <Route path="/index.html" Component={MainPage} />
                    <Route path="/Presentacion" Component={MainPage} />
                    <Route path="/Portfolio.Seat" Component={PortfolioSeatPage} />
                    <Route path="/Blog" Component={BlogPage} />
                    <Route path="/Cod/Begimo3" Component={Begimo3Page} />
                    <Route path="/Stack" Component={StackPage} />
                    <Route path="/To-Do" Component={TodoPage} />
                    <Route path="/React/Customers" Component={CustomersPage} />
                    <Route path="/React/Orders" Component={OrdersPage} />
                    <Route path="/React/Load.Excel" Component={LoadPage} />
                    <Route path="/React" Component={CustomersPage} />
                    <Route path="*" Component={MainPage} />
                </Routes>
            </Router>

            <script src="assets/js/jquery.min.js"></script>
            <script src="assets/js/skel.min.js"></script>
            <script src="assets/js/util.js"></script>
            <script src="assets/js/main.js"></script>

        </React.Fragment>
    );
}


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <MyMenu />
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }


export default App;


