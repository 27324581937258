import React from 'react'
//import './BlogPage.css'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Begimo3Page = () => {
    return (
        <React.Fragment>

            <Header />

            {/* Titulo */}
            <section className="wrapper" style={{ paddingBottom: 0 }}>
                <div className="inner">
                    <header className="align-center">
                        <h1 id="titulo">Manual para atacar al bégimo 3</h1>
                    </header>
                </div>
            </section>

            <div className="inner">
                <ol>
                    <li>Si no sabes si vas a estar online para el ataque, deja las tropas fuera, aparcadas en la puerta</li>
                    <li>En el momento de entrar al circulo de piedras, no entrar en el radio de accion del bégimo (pulsando en el bégimo se ve el circulo rojo que no debes tocar)</li>
                    <li>Estate atento a lo que dice el pájaro, y para la 2a vez sabras que ataques va a hacer con mucha antelación</li>
                    <li>Si el marco de la pantalla te sale en rojo, estas marcado y vas a explotar, vete al centro y sobretodo no explotes en el agua (esta explosión es muy peligrosa para los demás jugadores)</li>
                    <li>El pajaro lanza bolas de energia a 2 charcos, en cuanto lance una bola al charco donde estes, sal del charco</li>
                    <li>Cuando el pajaro está en el aire, saldran piedras que han de recoger al menos 8 jugadores</li>
                    <li>En la "primera" tanda se recogen las piedras de los charcos no electrificados</li>
                    <li>En la "segunda" tanda se recogen las otras 2 piedras directamente</li>
                    <li>Si sois 8 para recoger 1 piedra y el pajaro lanza la bola, esperar a recoger la roca antes de salir del charco</li>
                    <li>A los 8 minutos de batalla, mata a quien quede en la arena</li>
                    <li>Cualquier fallo que se haga recogiendo piedras supone ir al limite de tiempo o no poder matarlo a tiempo</li>
                </ol>
            </div>

            <Footer />

        </React.Fragment>
    )
}

export default Begimo3Page