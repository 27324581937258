import React from 'react'
import './BlogPage.css'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const BlogPage = () => {
    return (
        <React.Fragment>

            <Header />

            {/* Titulo */}
            <section className="wrapper" style={{ paddingBottom: 0 }}>
                <div className="inner">
                    <header className="align-center">
                        <h1 id="titulo">Blog autoclean</h1>
                        <p>Aplicacion web a modo de ejempo de Redis (base de datos en memoria) para guardar los comentarios del blog durante 1 hora.</p>
                    </header>
                </div>
            </section>

            {/* Blog */}
            <section className="wrapper" style={{ padding: 0 }}>
                <div style={{ width: '100%', float: 'left' }}>
                    <div className="inner" id="pantalla">
                        <div id="screen">System: Hola, deja tu mensaje aquí y quedará a la vista por 1 hora.<br />_</div>
                        <div id="separador-pie"> </div>
                        <div id="pie"></div>
                        <div id="separador-pie"> </div>
                        <div id="separador-peana"> </div>
                        <div id="peana"></div>
                        <div id="separador-peana"> </div>
                    </div>
                </div>
                <div className="inner">
                    <div id="formulario">
                        <form>
                            <label htmlFor="alias">Alias:</label>
                            <input type="text" style={{ width: '20%', float: 'left' }} id="alias" name="alias" placeholder="Anónimo" />
                            <label htmlFor="comentario">Comentario:</label>
                            <input type="text" style={{ width: '40%', float: 'left' }} id="comentario" name="comentario" placeholder="Comentario" />
                            <input type="button" style={{ marginLeft: '10px' }} value="Enviar" onClick={mensaje} />
                            {/* <button style="margin-left: 10px;" onclick="mensajito()">Enviar</button>				 */}
                        </form>
                    </div>
                </div>
            </section>

            {/* TODO: Powered by Redis (y poner logo de redis) */}

            {/* <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>
		
        <script>
			function mensajito()
			{
				Swal.fire({
						title: '<strong>HTML <u>example</u></strong>',
						icon: 'info',
						html:
						  'You can use <b>bold text</b>, ' +
						  'and other HTML tags',
						showCloseButton: true,
						showCancelButton: true,
						focusConfirm: false,
						confirmButtonText:
						  '<i className="fa fa-thumbs-up"></i> Great!',
						confirmButtonAriaLabel: 'Thumbs up, great!',
						cancelButtonText:
						  '<i className="fa fa-thumbs-down"></i>',
						cancelButtonAriaLabel: 'Thumbs down'
					  })
			}
		</script> */}

            <Footer />

        </React.Fragment>
    )
}

function mensaje()
{
    alert('Picaste. \nNo, ahora en serio, está en desarrollo.');
}    

export default BlogPage