import React from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, Edit, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import { registerLicense } from '@syncfusion/ej2-base'; // importa registerLicense en lugar de setLicense
import '../../css/syncfusion.css';
import { StackData } from '../StackData/StackData';

registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQllhTH9Ud0NiXXxXcnU=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpRc0RkWHlfdHBVQ2E=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdEBiXn5YcXBRR2ld;MTg0MDA3NEAzMjMxMmUzMTJlMzMzNWFpeU1ROUxlakRDS0k1NjRvV1JUQVMxWjlJR3BOUDVLa0NXTFQvTHo4cnM9;MTg0MDA3NUAzMjMxMmUzMTJlMzMzNWRQeENpNjNLSGZwRnVWSWxyTDhCM0JHdDQveUpBV0U0SXR3ZWljSE0zVmM9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckdmWHhfdnVRQmleUA==;MTg0MDA3N0AzMjMxMmUzMTJlMzMzNVRlVHR2TGJNU0szZCtKVGRsNUxNYzMzQmxtWFJraEdzUXFra29yNzZoT2s9;MTg0MDA3OEAzMjMxMmUzMTJlMzMzNVllQW81UnRQS0xPbkZuU2N6cHpvQ01IQ1lNRjJzZE85NXh1d0NwVmJEOU09;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdEBiXn5YcXBQQGJd;MTg0MDA4MEAzMjMxMmUzMTJlMzMzNUZkM3hxTlc3elJCZit3T29kdC9WUDhySTN1TVVUOUFHQnZzMXpITEM1VUE9;MTg0MDA4MUAzMjMxMmUzMTJlMzMzNUp1eGZSQ0pVelFzTEsxZ3VVVXJNVVZUUVhnbkxvdTBZZW9IKzZvVHprUUE9;MTg0MDA4MkAzMjMxMmUzMTJlMzMzNVRlVHR2TGJNU0szZCtKVGRsNUxNYzMzQmxtWFJraEdzUXFra29yNzZoT2s9');

function TodoGrid() {
    const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Cancel', 'Print']; /*'Update'*/

    //{/*template={(data) => data.Conocimientos + '%'} Imprime en blanco*/ }

    return (
        <GridComponent dataSource={StackData} editSettings={editOptions} toolbar={toolbarOptions} height={500}>
            <ColumnsDirective>
                <ColumnDirective width='5%' field='Valor' headerText='Valor' textAlign="Right" isPrimaryKey={false} editType='numericedit' />
                <ColumnDirective width='25%' field='Tecnologia' headerText='Tecnologia' />
                <ColumnDirective width='10%' field='Tipo' headerText='Tipo' textAlign="Right" editType='dropdownedit' />
                <ColumnDirective width='10%' field='Conocimientos' headerText='% Conocimientos' editType='numericedit' textAlign="Right" />
                <ColumnDirective width='50A %' field='To-Do' headerText='To-Do' />
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar]} />
        </GridComponent>
    );
}

export default TodoGrid;