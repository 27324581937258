import React from 'react'
import '../../css/font-awesome.min.css'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <div>
            <section id="banner" style={{ backgroundImage: 'url("/assets/images/banner.jpg")' }}>
                {/* 		
                <div className="image round">
                    <img src="assets/images/frandiaz_redonda.png" alt="Foto Fran Diaz"/>
                </div>
                <p style={{fontsize: '24px'}}>Fran Díaz Guerrero</p> */}
                <h1>Pasión por el desarrollo</h1>
            </section>

            <header id='header'>
                <div className="inner">
                    <Link to="/Presentacion" className="logo">www.FranDiaz.info</Link>
                    <nav id="nav">
                        <Link to="/Presentacion" >Presentación</Link>
                        <Link to="/Stack" >Stack</Link>
                        <Link to="/Portfolio.Seat">Portfolio Seat</Link>
                        <Link to="/React">Ejemplo</Link>
                        <Link to="/Blog">Blog</Link>
                        <a href="https://www.linkedin.com/in/frandiaz1979/"><img src="/assets/images/icon_linkedin.svg" alt="linkedin" height="20px" /></a>
                        <a href="https://github.com/frandiaz1979"><img src="/assets/images/icon_github_white.svg" alt="github" height="20px" /></a>
                        <a href="https://learn.microsoft.com/es-es/users/frandiaz1979/"><img src="/assets/images/icon_microsoft.svg" alt="Microsoft" height="20px" /></a>
                    </nav>
                    <div className="navPanelToggle" style={{ backgroundColor: 'lightblue' }}>
                        {/*<span className="fa fa-bars"></span>*/}
                        <Link className="item" to="/Presentacion" >Presentación</Link>
                        <Link className="item" to="/Stack" >Stack</Link>
                        <Link className="item" to="/Portfolio.Seat">Portfolio Seat</Link>
                        <Link className="item" to="/React">Ejemplo</Link>
                        <Link className="item" to="/Blog">Blog</Link>
                        <a className="icono" href="https://www.linkedin.com/in/frandiaz1979/"><img src="/assets/images/icon_linkedin.svg" alt="linkedin" height="20px" /></a>
                        <a className="icono" href="https://github.com/frandiaz1979"><img src="/assets/images/icon_github_black.svg" alt="github" height="20px" /></a>
                        <a className="icono" href="https://learn.microsoft.com/es-es/users/frandiaz1979/"><img src="/assets/images/icon_microsoft.svg" alt="Microsoft" height="20px" /></a>
                    </div>
                </div>
            </header>


        </div>
    );
}

export default Header;