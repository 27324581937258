import React, { useState } from 'react';
import XLSX from 'xlsx';
import Menu from './Submenu'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const LoadPage = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                const jsonString = JSON.stringify(jsonData);
                fetch('http://frandiaz.info:7260/api/orders', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: jsonString
                })
                    .then(response => {
                        if (response.ok) {
                            alert('Datos cargados con éxito');
                        } else {
                            alert('La web api ha devuelto un error');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        alert('Ha ocurrido un error mientras se cargaban los datos');
                    });
            };
            reader.readAsArrayBuffer(selectedFile);
        } else {
            alert('Seleccione un Excel para cargar');
        }
    };
    /*.then(response => response.json())*/

    return (
        <React.Fragment>
            <Header />

            <Menu />

            <div className="inner">

                <h3>Cargar Excel</h3>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Archivo Excel: </label>
                        <input
                            type="file"
                            style={{ width: '20%' }}
                            id="archivo"
                            name="archivo"
                            accept=".xlsx, .xls, .csv"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <input type="submit" value="Cargar" />
                    </div>
                </form>

            </div>

            <Footer />
        </React.Fragment>
    );
};

export default LoadPage;
