import React from 'react'
import OrdersGrid from './OrdersGrid'
import Menu from './Submenu'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const OrdersPage = () => {
    return (
        <React.Fragment>
            <Header />

            <Menu />

            <div className="inner">

                <h3>Pedidos</h3>

                <OrdersGrid />

            </div>

            <Footer />
        </React.Fragment>
    )
}

export default OrdersPage