import React, { Component } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, Edit, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import '../../css/syncfusion.css';

class CustomersGrid extends Component {
    state = {
        data: []
    };

    componentDidMount() {
        axios.get('http://frandiaz.info:7260/api/customers')
            .then(response => {
                this.setState({ data: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    // npm TODO: Hacer que las toolbarOptions guarden en la base de datos

    render() {
        const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
        const toolbarOptions = []; //['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        return (
            <GridComponent dataSource={this.state.data} editSettings={editOptions} toolbar={toolbarOptions} height={265} width={500}>
                <ColumnsDirective>
                    <ColumnDirective field='Id' headerText='Codigo' width='100' textAlign="Right" isPrimaryKey={true} />
                    <ColumnDirective field='Name' headerText='Nombre' width='240' textAlign="Right" />
                </ColumnsDirective>
                <Inject services={[Edit, Toolbar]} />
            </GridComponent>
        );
    }
}

export default CustomersGrid;
