export let StackData = [
    {
        'Tecnologia': 'C#',
        'Tipo': '.Net',
        'Valor': 5,
        'Conocimientos': 100,
        'To-Do' : 'Resumen de net 8 y C#11 hacia abajo. Listado de que falta'
    },
    {
        'Tecnologia': 'VB.NET',
        'Tipo': '.Net',
        'Valor': 1,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': '.Net Framework',
        'Info': '4.0 / 4.5 / 4.7.2',
        'Tipo': '.Net',
        'Valor': 5,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': '.Net Core',
        'Info': '.Net Core 3.1 / .Net 6 / .Net 7',
        'Tipo': '.Net',
        'Valor': 5,
        'Conocimientos': 90,
    },
    {
        'Tecnologia': 'GIT',
        'Tipo': '.Net',
        'Valor': 5,
        'Conocimientos': 90,
        'To-Do' : 'Repasar la hoja de comandos frecuentemente'
    },
    {
        'Tecnologia': 'Entity Framework ',
        'Info': 'Incluye Core',
        'Tipo': '.Net',
        'Valor': 4,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'Dapper',
        'Info': 'ORM',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'NUnit',
        'Info': 'Pruebas automatizadas unitarias y de integración',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 80,
    },
    {
        'Tecnologia': 'Selenium',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 60,
    },
    {
        'Tecnologia': 'Patrones',
        'Info': 'Incluye OOP',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 60,
    },
    {
        'Tecnologia': 'WPF',
        'Info': 'MVC y XAML. Genera .exe',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 40,
    },
    {
        'Tecnologia': 'LINQ',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'Multithreading',
        'Info':'Thread, Task, async/await',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 50,
    },
    {
        'Tecnologia': 'AutoFac',
        'Info': 'En Core ya viene un Container de dependencias',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'WebSocket',
        'Info': 'protocolo para comunicación en tiempo real',
        'Tipo': '.Net',
        'Valor': 0,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'SignalR core',
        'Info': 'biblioteca .net que usa WebSocket y otros',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 0,
        'To-Do': 'Hacer página/app con signalR usando websocket'
    },
    {
        'Tecnologia': 'Inyección de dependencias',
        'Tipo': '.Net',
        'Valor': 4,
        'Conocimientos': 70,
    },
    {
        'Tecnologia': 'WinForms',
        'Info': 'aka Windows Forms, aplicaciones de escritorio',
        'Tipo': '.Net',
        'Valor': 1,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'MVC',
        'Tipo': '.Net',
        'Valor': 5,
        'Conocimientos': 90,
        'To-Do' : 'Falta aprender de routing y subir algo a GitHub. Lista de pendientes',
    },
    {
        'Tecnologia': 'WebForms',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 70,
    },
    {
        'Tecnologia': 'Web Api Rest',
        'Tipo': '.Net',
        'Valor': 5,
        'Conocimientos': 80,
        'To-Do': 'Hacer listado de lo que faltaria por aprender',
    },
    {
        'Tecnologia': 'Web Service SOAP con WCF',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Web Api gRPC',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'gRPC en aplicaciones distribuidas',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Razor',
        'Info': 'Usado en MVC',
        'Tipo': '.Net',
        'Valor': 4,
        'Conocimientos': 80,
        'To-Do': 'Listado de que falta'
    },
    {
        'Tecnologia': 'Blazor',
        'Info': 'SPA',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 20,
        'To-Do': 'Aplicacion Blazor Web Assembly App (puede correr en nginx)'
    },
    {
        'Tecnologia': '.Net Maui',
        'Info': 'Movil y escritorio en C# y XAML',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 0,
        'To-Do': 'Probar en Visual Studio'
    },
    {
        'Tecnologia': 'RabbitMQ',
        'Info': 'Message Broker',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 20,
    },
    {
        'Tecnologia': 'Azure Service Bus',
        'Info': 'Message Broker de Azure',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Xamarin',
        'Info': 'Deprecado por .Net Maui',
        'Tipo': '.Net',
        'Valor': 0,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'OpenTelemetry',
        'Tipo': '.Net',
        'Valor': 3,
        'Conocimientos': 20,
    },
    {
        'Tecnologia': 'SeriLog o NLog',
        'Tipo': '.Net',
        'Valor': 2,
        'Conocimientos': 50,
    },
    {
        'Tecnologia': 'JavaScript',
        'Tipo': 'Web',
        'Valor': 4,
        'Conocimientos': 50,
    },
    {
        'Tecnologia': 'HTML',
        'Tipo': 'Web',
        'Valor': 4,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'CSS',
        'Tipo': 'Web',
        'Valor': 4,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'Ajax',
        'Tipo': 'Web',
        'Valor': 2,
        'Conocimientos': 10,
    },
    {
        'Tecnologia': 'jQuery',
        'Tipo': 'Web',
        'Valor': 3,
        'Conocimientos': 30,
    },
    {
        'Tecnologia': 'Bootstrap',
        'Tipo': 'Web',
        'Valor': 1,
        'Conocimientos': 30,
    },
    {
        'Tecnologia': 'TypeScript',
        'Tipo': 'Web',
        'Valor': 3,
        'Conocimientos': 0,
        'To-Do': 'Probar en Visual Studio'
    },
    {
        'Tecnologia': 'Angular',
        'Tipo': 'Web',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'React',
        'Tipo': 'Web',
        'Valor': 2,
        'Conocimientos': 70,
    },
    {
        'Tecnologia': 'SQL',
        'Info': 'Incluye SQL Server y PL/SQL',
        'Tipo': 'BBDD',
        'Valor': 5,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'Azure CosmosDB',
        'Info': 'No sql',
        'Tipo': 'BBDD',
        'Valor': 3,
        'Conocimientos': 40,
        'To-Do': 'Probar'
    },
    {
        'Tecnologia': 'Redis',
        'Tipo': 'BBDD',
        'Valor': 2,
        'Conocimientos': 80,
    },
    {
        'Tecnologia': 'Docker',
        'Tipo': 'CI/CD',
        'Valor': 3,
        'Conocimientos': 80,
    },
    {
        'Tecnologia': 'Jenkins',
        'Tipo': 'CI/CD',
        'Valor': 2,
        'Conocimientos': 40,
    },
    {
        'Tecnologia': 'GitHub Actions',
        'Tipo': 'CI/CD',
        'Valor': 3,
        'Conocimientos': 20,
    },
    {
        'Tecnologia': 'Azure DevOps',
        'Tipo': 'CI/CD',
        'Valor': 3,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Kubernetes',
        'Tipo': 'CI/CD',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'OpenShift',
        'Tipo': 'CI/CD',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Terraform',
        'Info': 'IaC',
        'Tipo': 'CI/CD',
        'Valor': 2,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Azure',
        'Tipo': 'Cloud',
        'Valor': 3,
        'Conocimientos': 55,
    },
    {
        'Tecnologia': 'AWS',
        'Tipo': 'Cloud',
        'Valor': 2,
        'Conocimientos': 30,
    },
    {
        'Tecnologia': 'Principios SOLID',
        'Tipo': 'Ing. Soft.',
        'Valor': 4,
        'Conocimientos': 80,
    },
    {
        'Tecnologia': 'Clean Code',
        'Tipo': 'Ing. Soft.',
        'Valor': 4,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'DDD',
        'Tipo': 'Ing. Soft.',
        'Valor': 4,
        'Conocimientos': 30,
    },
    {
        'Tecnologia': 'Arquitectura n-capas',
        'Tipo': 'Ing. Soft.',
        'Valor': 2,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'Arquitectura Onion',
        'Info':'Clean Architecture',
        'Tipo': 'Ing. Soft.',
        'Valor': 3,
        'Conocimientos': 50,
    },
    {
        'Tecnologia': 'Arquitectura Hexagonal',
        'Info': 'aka puertos y adaptadores',
        'Tipo': 'Ing. Soft.',
        'Valor': 3,
        'Conocimientos': 0,
    },
    {
        'Tecnologia': 'Arquitectura orientada a Servicios',
        'Info': 'aka SOA',
        'Tipo': 'Ing. Soft.',
        'Valor': 1,
        'Conocimientos': 50,
    },
    {
        'Tecnologia': 'Desarrollo de microservicios con eventos',
        'Tipo': 'Ing. Soft.',
        'Valor': 3,
        'Conocimientos': 20,
    },
    {
        'Tecnologia': 'CQRS',
        'Info': '',
        'Tipo': 'Ing. Soft.',
        'Valor': 3,
        'Conocimientos': 40,
    },
    {
        'Tecnologia': 'UML',
        'Tipo': 'Ing. Soft.',
        'Valor': 2,
        'Conocimientos': 20,
    },
    {
        'Tecnologia': 'Inglés',
        'Tipo': 'Inglés',
        'Valor': 4,
        'Conocimientos': 50,
    },
    {
        'Tecnologia': 'Scrum',
        'Tipo': 'Gestion',
        'Valor': 3,
        'Conocimientos': 70,
    },
    {
        'Tecnologia': 'Agile',
        'Tipo': 'Gestion',
        'Valor': 2,
        'Conocimientos': 100,
    },
    {
        'Tecnologia': 'Project Management',
        'Tipo': 'Gestion',
        'Valor': 3,
        'Conocimientos': 70,
    },
    {
        'Tecnologia': 'Gestion de Equipos',
        'Tipo': 'Gestion',
        'Valor': 3,
        'Conocimientos': 40,
    },
    {
        'Tecnologia': 'Mentorización',
        'Tipo': 'Gestion',
        'Valor': 3,
        'Conocimientos': 80,
    },
    {
        'Tecnologia': 'Inteligencia Emocional',
        'Tipo': 'Gestion',
        'Valor': 2,
        'Conocimientos': 80,
    },
    {
        'Tecnologia': 'PNL',
        'Info': 'Programación Neurolinguística',
        'Tipo': 'Gestion',
        'Valor': 2,
        'Conocimientos': 40,
    },
    {
        'Tecnologia': '¿Powershell o Bash?',
        'Tipo': 'Otros',

        'Conocimientos': 0,
    },
    {
        'Tecnologia': '¿Phyton o Django?',
        'Tipo': 'Otros',
        'Conocimientos': 0,
    },
    {
        'Tecnologia': '¿ElasticSearch?',
        'Tipo': 'Otros',
        'Conocimientos': 0,
    },
    {
        'Tecnologia': '¿NServiceBus?',
        'Info': 'Message-Bus',
        'Tipo': 'Otros',
        'Conocimientos': 0,
    },
    {
        'Tecnologia': '¿Polly?',
        'Info': 'Biblioteca para reintentos de llamada http',
        'Tipo': 'Otros',
        'Conocimientos': 0,
    },
    {
        'Tecnologia': '¿YAML / IaC?',
        'Tipo': 'Otros',
        'Conocimientos': 0,
    },
];