import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import { StackData } from '../StackData/StackData';

function StackChart() {
    useEffect(() => {
        const sumaNetValor = StackData.filter(dato => dato.Tipo === '.Net').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaNetConocimiento = StackData.filter(dato => dato.Tipo === '.Net').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalNet = sumaNetConocimiento * 100 / sumaNetValor
        const sumaWebValor = StackData.filter(dato => dato.Tipo === 'Web').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaWebConocimiento = StackData.filter(dato => dato.Tipo === 'Web').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalWeb = sumaWebConocimiento * 100 / sumaWebValor
        const sumaBBDDValor = StackData.filter(dato => dato.Tipo === 'BBDD').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaBBDDConocimiento = StackData.filter(dato => dato.Tipo === 'BBDD').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalBBDD = sumaBBDDConocimiento * 100 / sumaBBDDValor
        const sumaCICDValor = StackData.filter(dato => dato.Tipo === 'CI/CD').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaCICDConocimiento = StackData.filter(dato => dato.Tipo === 'CI/CD').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalCICD = sumaCICDConocimiento * 100 / sumaCICDValor
        const sumaCloudValor = StackData.filter(dato => dato.Tipo === 'Cloud').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaCloudConocimiento = StackData.filter(dato => dato.Tipo === 'Cloud').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalCloud = sumaCloudConocimiento * 100 / sumaCloudValor
        const sumaIngValor = StackData.filter(dato => dato.Tipo === 'Ing. Soft.').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaIngConocimiento = StackData.filter(dato => dato.Tipo === 'Ing. Soft.').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalIng = sumaIngConocimiento * 100 / sumaIngValor
        const sumaGestionValor = StackData.filter(dato => dato.Tipo === 'Gestion').map(dato => dato.Valor).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const sumaGestionConocimiento = StackData.filter(dato => dato.Tipo === 'Gestion').map(dato => dato.Valor * dato.Conocimientos / 100).reduce((acumulador, numero) => { return acumulador + numero; }, 0);
        const totalGestion = sumaGestionConocimiento * 100 / sumaGestionValor

        const ctx = document.getElementById('myChart');
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['.Net', 'Web', 'BBDD', 'CI/CD', 'Cloud', 'Ing. Soft.', 'Gestion'],
                datasets: [{
                    label: '% de aprendizaje estimado por areas',
                    data: [totalNet, totalWeb, totalBBDD, totalCICD, totalCloud, totalIng, totalGestion],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });
    }, []);

    return (
        <React.Fragment>
            <canvas id="myChart"></canvas>
        </React.Fragment>
    );

}

export default StackChart;