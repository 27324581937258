import React from 'react'
import { Link } from 'react-router-dom'
import './Submenu.css'

const Menu = () => {
    return (
        <React.Fragment>
            <div className='BarraSubmenu'>
                <div className='TituloSubmenu'> Ejemplo con React de acceso a APIs </div>
                <div className='LinkSubmenu'><Link to="/React/Customers">Clientes</Link></div>
                <div className='LinkSubmenu'><Link to="/React/Orders">Pedidos</Link></div>
                <div className='LinkSubmenu'><Link to="/React/Load.Excel">Cargar Excel</Link></div>
            </div>
            <p> </p>
        </React.Fragment>
    )
}

export default Menu