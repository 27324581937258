import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const PortfolioSeatPage = () => {
  return (
    <React.Fragment>

        <Header />

		{/* Titulo */}
		<section className="wrapper" style={{paddingBottom:0}}>
			<div className="inner">
				<header className="align-center">
					<h1 id="titulo">Portfolio de Seat</h1>
					<p>Aplicaciones de escritorio desarrolladas integramente por mi en 2 años</p>
					<p>He tenido la precaución de simular los datos, pero los pantallazos son reales, si no se amplian automaticamente las imagenes al clicarlas, merece la pena abrirlas en una pestaña nueva y ver la cuidada selección de iconos y los nombres que tienen dan información de características implementadas</p>
				</header>
			</div>
		</section>	

		{/* Valoraciones */}
		<section className="wrapper" style={{padding:0}}>
			<div className="inner">
				<header className="align-center">
					<h2>Valoraciones</h2>
					<p>Analisis distribuido de costes</p>
				</header>
				<p>Mi primera aplicación en Seat y la que hizo que me pidiesen más aplicaciones, alargando mi estancia hasta el máximo que se pudo por budget o presupuesto.</p>
				<p>Cuando llegué, la estimación de costes se pedía por mail, y cada departamento debía rellenar un excel y hacerlo llegar a su superior, este rellenaba otra vez la plantilla sumando las estimaciones de sus subdepartamentos.</p>
				<p>Mi aplicación automatiza todo el proceso incluido el envio de mails a modo de recordatorio, la pantalla de inicio es distinta según el rol, los responsables de departamento ven que valoraciones estan respondidas y cuales estan pendientes, se pueden bloquear las valoraciones para que no sean editables.</p>
				<p></p>
				<div className="flex flex-2">
					<article>
						<div className="image fit">
							<img src="assets/images/CapturaValoraciones.png" alt="Pic 01" />
						</div>
						<header>
							<h3>Valoración distribuida</h3>
						</header>
						<p>Cada departamento puede estimar un coste en horas, dinero o recursos.</p>
					</article>
					<article>
						<div className="image fit">
							<img src="assets/images/CapturaValoraciones2.png" alt="Pic 02" />
						</div>
						<header>
							<h3>Facil Gestión</h3>
						</header>
						<p>Los responsables ven en tiempo real que departamento falta por contestar, el listado de valoraciones abiertas, pueden cambiar el estado de las valoraciones a canceladas, en estudio, aceptadas... </p>
					</article>
				</div>
			</div>
		</section>

		{/* Recursos */}
		<section className="wrapper">
			<div className="inner">
				<header className="align-center">
					<h2>Recursos</h2>
					<p>Gestión de vehículos de pruebas</p>
				</header>
				<p>Seat tiene un parque de vehiculos de pruebas de 700 unidades de media, a un precio medio de 120.000€ da un total de 80 millones de euros. Estos vehículos se intentan aprovechar al máximo, pero que circulen varias versiones de excels con el listado de coches ademas de archivos en MS Project, hacia dificil los cambios en la planificación. Cada 1% de mejora ahorraba 800.000€ y creo que la mejora fue muy elevada.</p>
				<p>Mi aplicación muestra los datos en tiempo real, tanto el listado de los coches con sus características como la planificación de las pruebas y las fechas.</p>
				<p>Compartir vehiculos entre departamentos nunca fue tan fácil, cualquier persona ve el listado de coches y quien lo tiene en cada momento, haciendo que quien necesite un coche filtre por sus necesidades y viendo el listado de candidatos y que departamento lo tendrá, facilitando el intercambio de coches.</p>					
				<p>Puede ver facilmente la planificación mediante un diagrama de Gantt (usado en Microsoft Project)</p>
				<p>Se pueden filtrar los diagramas y listados por 1 solo departamento, cada departamento le sacara partido a esta utilidad, pueden organizar que dias dispondran de un recurso.</p>
				<div className="flex flex-2">
					<article>
						<div className="image fit">
							<img src="assets/images/CapturaRecursos1.png" alt="Pic 01" />
						</div>
						<header>
							<h3>Entorno muy visual</h3>
						</header>
						<p>El plannig tiene formato de diagrama de Gantt (usado en MS Project)</p>
					</article>
					<article>
						<div className="image fit">
							<img src="assets/images/CapturaRecursos2.png" alt="Pic 02" />
						</div>
						<header>
							<h3>Control del uso</h3>
						</header>
						<p>Listados y gráficas de varios tipos para ver, por ejemplo, que departamentos usan más vehículos o que tipos de vehiculos son los más usados.</p>
					</article>
				</div>
			</div>
		</section>

		{/* Documentación */}
		<section className="wrapper">
			<div className="inner">
				<header className="align-center">
					<h2>Gestión documental</h2>
					<p>Gestión de documentos confidenciales</p>
				</header>
				<p>1 departamento tenia varias necesidades, tenía documentación muy confidencial (por ejemplo, passwords de modelos de centralitas) que tenia que compartir con ciertas personas y en cada proyecto se tenian que repetir una serie de procesos y obtener una documentación con resultados.</p>
				<p>Esta aplicación es la más extravagante que he creado.</p>
				<p>Los proyectos se ven en forma de arbol de carpetas, dentro de los cuales ha las carpetas y archivos que hubiese en la plantilla en el momento de crearse el proyecto.</p>
				<p>Las personas con permiso pueden entrar y obtener la documentación, y los responsables pueden subir archivos.</p>
				<p>Dada la confidencialidad de la documentación, los archivos se guardaban cifrados en una ubicación de red. Yo no tenia permiso a dicha ubicación, y si alguien accedía a la carpeta sin tener la app (por ejemplo, un administrador de sistemas), no obtendría ningún dato.</p>
				<p>Por último destacar que las carpetas tenian diferente color en función del estado del proyecto, o del estado del "paso".</p>
				<div className="flex flex-2">
					<article>
						<div className="image fit">
							<img src="assets/images/CapturaVDC.png" alt="Visor de Documentación Confidencial" />
						</div>
						<header>
							<h3>Máxima confidencialidad</h3>
						</header>
						<p>Herramienta que facilita la gestión de documentos confidenciales en los proyectos</p>
					</article>
				</div>
			</div>
		</section>        
        
        <Footer />

    </React.Fragment>
  )
}

export default PortfolioSeatPage