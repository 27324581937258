import React from 'react';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import StackChart from './StackChart';
import StackGrid from './StackGrid';

const StackPage = () => {

    return (
        <React.Fragment>

            <Header />

            {/* Titulo */}
            <section className="wrapper" style={{ paddingBottom: 0 }}>
                <div className="inner">
                    <header className="align-center">
                        <h1 id="titulo">Mi Stack</h1>
                    </header>
                </div>
            </section>

            <div className="inner">
                <p>Este es mi stack a fecha 25 de abril de 2023.</p>
                {/* format="C2" en dolares, con 2 decimales */}

                <p>El valor es la puntuación que le doy yo a la tecnología en función de ofertas de trabajo, dificultad, etc, siendo 5 el máximo.</p>
                <p>Conocimientos es el % de conocimientos que tengo de ello (un 100% no supone saberlo todo, sino tener conocimientos avanzados y haberlos implementado en varias ocasiones)</p>

                <StackGrid />

                <p></p>
                <p>Permito que se edite por si alquien quiere sacar un listado con sus propios criterios. Haciendo F5 vuelve a coger los valores por defecto</p>
                <p>El grid es de la empresa syncfusion, con licencia gratuita por ser para uso personal</p>

                <StackChart />
            </div>

            <Footer />

        </React.Fragment>
    )
}

export default StackPage