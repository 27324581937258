import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const MainPage = () => {
    return (
        <div>
            <Header />

            {/* Presentacion */}
            <section id="Presentacion" className="wrapper">
                <header className="align-center">
                    <h1>Presentación</h1>
                </header>
                <div className="inner">
                    <img src="assets/images/FranDiaz_Presenta.png" alt="Fran Diaz Presenta" style={{ float: 'right' }} />
                    {/* <p>Como puedes ver en esta página, tengo iniciativa, por cierto las tecnologías 
                        usadas para está pagina son html, css y javascript, con el editor Visual Code, 
                        metida en un contenedor Docker de Linux Alpine.
                        (Si, en vez de contratar un hospedaje de web sencillo, 
                        he elegido un entorno Docker donde tambien puedo hacer pruebas en un servidor público)</p> */}

                    <p>Soy programador con 15 años de experiencia en el mundo de la programación e IT, de ellos, los últimos 7 años en Visual Studio y .NET</p>
                    <p>Como soft skills destacan las relacionadas con la comprensión y solución de problemas y proyectos complejos.</p>
                    <p>Como habilidades sociales practico la inteligencia emocional, la escucha activa y reconozco las ideas de los demás si son mejores.</p>
                    <p>Tras 15 años de experiencia en programación e IT, y haber participado en todas las fases de creación de aplicaciones, me da una visión 360 y muy orientada a conseguir los objetivos/satisfacción del cliente.</p>
                    <p>A nivel técnico, sigo aprendiendo cada día, sobre todo buenas prácticas, Cloud, contenedores, etc.</p>
                    <p>A nivel personal, además de seguir aprendiendo de la vida, le estoy muy agradecido por tener un empleo en el que me pagan por desarrollar mi hobby.</p>
                </div>
                <footer className="align-center">
                    <a href="assets/docs/2023.03.11_CV_Fran_Diaz_downloaded.pdf" className="button special">Descargar CV</a>
                </footer>
            </section>

            {/* Certificaciones */}
            <section id="three" className="wrapper special">
                <div className="inner">
                    <header className="align-center">
                        <h2>Certificaciones</h2>
                    </header>
                    <div className="flex flex-3">
                        <article>
                            <div className="image fit">
                                <img src="assets/images/certificacion_az900.png" alt="Pic 01" />
                            </div>
                            <p></p>
                            <header>
                                <h3>AZ-900</h3>
                            </header>
                            <p>Microsoft Certified: Azure Fundamentals (obtenida en inglés)</p>
                            <footer className="align-center">
                                <a href="assets/docs/Certificacion_AZ900_FranDiaz.pdf" className="button special">Descargar</a>
                                <a href="https://www.credly.com/badges/13251e7a-c21b-4fcf-9717-0f2c62407538" className="button special">Verificar</a>
                            </footer>
                        </article>
                        <article>
                            <div className="image fit">
                                <img src="assets/images/certificacion_pm_coursera.png" alt="Pic 02" />
                            </div>
                            <header>
                                <h3>Iniciación y Planificación de Proyectos</h3>
                            </header>
                            <p>Curso de Project Management certificado por Coursera</p>
                            <footer className="align-center">
                                <a href="assets/docs/Certificacion_PM_Coursera_FranDiaz.pdf" className="button special">Descargar</a>
                                <a href="https://www.coursera.org/account/accomplishments/verify/6XBEEW49RHEN" className="button special">Verificar</a>
                            </footer>
                        </article>
                        <article>
                            <div className="image fit">
                                <img src="assets/images/certificacion_pm_lasalle.png" alt="Pic 02" />
                            </div>
                            <header>
                                <h3>LaSalle: Project Management</h3>
                            </header>
                            <p>Curso de laSalle cuyas horas computan para el PMP</p>
                            <footer className="align-center">
                                <a href="assets/docs/Certificacion_PM_LaSalle_FranDiaz.pdf" className="button special">Descargar</a>
                            </footer>
                        </article>
                    </div>
                </div>
            </section>

            {/* Tecnologías preferidas */}
            <section id="two" className="wrapper style1 special">
                <div className="inner">
                    <header>
                        <h2>Tecnologías preferidas</h2>
                        <p>La 4 tecnologías por las que apuesto en mi carrera</p>
                    </header>
                    <div className="flex flex-4">
                        <div className="box person">
                            <div className="image">
                                <img src="assets/images/logo_microsoftnet.png" alt=".NET" height="120" />
                            </div>
                            <h3>.NET</h3>
                            <p>Plataforma de programación de Microsoft</p>
                        </div>
                        <div className="box person">
                            <div className="image">
                                <img src="assets/images/logo_sqlserver.png" alt="Sql Server" height="150" />
                            </div>
                            <h3>SQL Server</h3>
                            <p>Amplios conocimientos de SQL</p>
                        </div>
                        <div className="box person">
                            <div className="image">
                                <img src="assets/images/logo_azure.png" alt="Azure" />
                            </div>
                            <h3>Microsoft Azure</h3>
                            <p>Sacale partido a la nube</p>
                        </div>
                        <div className="box person">
                            {/* </div><div className="image round">*/}
                            <div className="image">
                                <img src="assets/images/logo_docker.png" alt="Docker" />
                            </div>
                            <h3>Docker</h3>
                            <p>Gana agilidad con contenedores</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Experiencia en otras tecnologías */}
            <section id="one" className="wrapper">
                <div className="inner">
                    <header className="align-center">
                        <h2>Experiencia en otras tecnologías</h2>
                    </header>
                    <div className="flex flex-3">
                        <article>
                            <header>
                                <h3>Git</h3>
                            </header>
                            <p>Años de experiencia de Git en GitLab, GitHub y Azure DevOps</p>
                        </article>
                        <article>
                            <header>
                                <h3>Diseño web<br /> y marqueting digital</h3>
                            </header>
                            <p>Creación de páginas web y posicionamiento</p>
                        </article>
                        <article>
                            <header>
                                <h3>Integración de sistemas<br /></h3>
                            </header>
                            <p>Conecte sus aplicaciones con su página web y su aplicación móvil,
                                multiplicando sus resultados
                            </p>
                        </article>
                        <article>
                            <header>
                                <h3>Analisis y desarrollo<br />de aplicaciones</h3>
                            </header>
                            <p>Amplia experiencia aportando soluciones a empresas y autónomos</p>
                        </article>
                        <article>
                            <header>
                                <h3>Bases de datos</h3>
                            </header>
                            <p>Muchisima experiencia en SQL, además de SQL Server, en los trabajos he usado Oracle y mySQL.</p>
                            <p>Tambien tengo conocimientos de mongoDB y Redis</p>
                        </article>
                        <article>
                            <header>
                                <h3>Programación</h3>
                            </header>
                            <p>Además de C#, he programado en VB.Net, Visual Basic 6, PHP y Delphi, todas ellas con experiencia laboral.</p>
                            <footer>
                            </footer>
                            {/* <footer className="align-center" style="padding-bottom: 35px;">
                                <a href="gestion.recursos.html" className="button special">Mas</a>
                            </footer> -*/}
                        </article>
                        <article>
                            <header>
                                <h3>Buenas prácticas</h3>
                            </header>
                            <p>Uso de Clean Code, principios SOLID, Clean Architecture (N-Capas)</p>
                            <p>Patrones de diseño</p>
                        </article>
                        <article>
                            <header>
                                <h3>Agile</h3>
                            </header>
                            <p>Experiencia en Scrum y Kanban</p>
                            <footer>

                            </footer>
                        </article>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default MainPage