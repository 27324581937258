import React from 'react'
import CustomersGrid from './CustomersGrid'
import Menu from './Submenu'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const CustomersPage = () => {
    return (
        <React.Fragment>

            <Header />

            <Menu />

            <div className="inner">

                <h3>Clientes</h3>

                <CustomersGrid style={{ maxWidth: '500px' }} />

            </div>

            <Footer />

        </React.Fragment>
    )
}

export default CustomersPage