import React from 'react'

 function Footer() {
  return (
	<footer id="footer">
		<div className="inner">
			<div className="flex">
				<div className="copyright">Web creada por Fran Diaz con React.</div>
				<ul className="icons">
					<li><a href="https://www.linkedin.com/in/frandiaz1979/"><img src="/assets/images/icon_linkedin.svg" alt="linkedin" height="20px" 
                    	style={{height: '20px'}}/></a></li>
					<li><a href="https://github.com/frandiaz1979"><img src="/assets/images/icon_github_black.svg" alt="github" height="20px"/></a></li>
					<li><a href="https://learn.microsoft.com/es-es/users/frandiaz1979/"><img src="/assets/images/icon_microsoft.svg" alt="Microsoft" height="20px" 
						style={{height:'20px'}}/></a></li>
				</ul>
			</div>
		</div>
	</footer>
  )
}

export default Footer;
